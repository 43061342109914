.imageLoading{
    max-height: 100px;
}
.titleSweet{
    font-size: 30px;
    font-weight: 700;
    color: black;
    margin-top: 30px;
    font-family: "Muli";
}
.overlayLoading{
    position: absolute; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

.super-spinner {
    box-sizing: border-box;
    position: relative;
    margin: auto;
    width: 128px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 16px solid transparent;
    border-top-color: #eb4511;
    border-bottom-color: #b60b00;
    transform: rotateZ(-45deg);
    animation: super-spinner 5s linear infinite;
  }
  .super-spinner::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    inset: -16px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 16px solid transparent;
    border-right-color: #eb4511;
    border-left-color: #b60b00;
    animation: super-spinner 5s linear infinite;
  }
  @keyframes super-spinner {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(360deg);
    }
    75% {
      transform: rotateZ(720deg);
    }
    100% {
      transform: rotateZ(1080deg);
    }
  }