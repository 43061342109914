.icon-input-primary {
    color: #A4080E;
    height: 20px;
    width: 20px;
}

.icon-input-sub {
    height: 18px;
    width: 18px;

    &.coin {
        border-radius: 50%;
        object-fit: cover;
    }
}

.form-control {
    font-size: 15px !important;

    @media (max-width: 1024px) {
        font-size: 13px !important;
    }
}

.sub {
    &-icon {
        display: flex;
        padding: 0px 0px 0px 10px;
        align-items: center;

        &-item {
            color: #BDBDBD;
            transition: .2s;

            margin-right: 10px;

            &:last-child {
                margin-right: 0px;
            }

            &:hover {
                cursor: pointer;
                color: #A4080E;
            }
        }
    }
}

.hide-valid-invalid {
    background-image: none !important;
    // -webkit-appearance: none;
}

.icon-inside-input-left {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    & > div {
        margin-right: 10px;
        color: gray;
        transition: .2s;

        &:hover {
            cursor: pointer;
            color: #A4080E;
        }

        & > svg {
            width: 20px;
            height: 20px;
        }

        &:last-child{
            margin-right: 0px;
        }
    }
}

