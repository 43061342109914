.waSi {

    &__address {
        display: flex;
        align-items: center;
        justify-content: center;

        &--content {
            position: relative;
        }
        
        &--render {
            padding: 10px 20px;
            background-color: white;
            border-radius: 50px;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.13);
            transition: .2s;

            &:hover {
                cursor: pointer;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.33);
            }
        }

        &--icon {
            position: absolute;
            top: 0; right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            transform: translateX(40px);

            & > div {
                transform: translateY(2px);
                color: gray;
                transition: .2s;

                &:hover {
                    cursor: pointer;
                    color: #A4080E;
                }

                & > svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}