.cusForm {

    &.signIn {
        width: 100%;
        max-width: 500px;
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        background-image: url("../assets/images/SignIn/Image2.png"), url("../assets/images/SignIn/Image1.png");
        background-position: top right, top right;
        background-repeat: no-repeat, no-repeat;
        background-size: 200px auto, 80% 200px;
        background-position-y: 20px, 0px;
        background-position-x: calc(100% - 100px) , 100%;

        @media (max-width: 490px) {
            background-position-x: calc(100% - 30px) , 100%;
            background-size: 150px auto, 80% 200px;
            background-position-y: 50px, 0px;
        }

        .signIn--btn {
            background-color: #B60B00;
            color: white;
            font-weight: 500;
            padding: 7px 0px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            transition: .2s;

            &.back {
                background-color: #F2F2F2;
                color: #BDBDBD;
            }

            &:hover {
                cursor: pointer;
                transform: translateY(-1px);
            }
        }
    }
}