.renderPrice {

    &__icon {
        height: 25px;
        width: 25px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 5px;
        transform: translateY(-1px);
    }

    &__price {
        font-weight: 600;

        &.VBC {
            color: #b60b00;
        }

        &.BNB {
            color: #f5c240;
        }

        &.WBNB , &.WVBC {
            color: #da3b7a;
        }
}
}