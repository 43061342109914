.headerSidebar {

    &__btn {
        height: 21px;
        width: 21px;
        transition: .2s;
        margin-right: 0px !important;

        &:hover {
            transform: translateY(-1px);
            cursor: pointer;
        }

        & > svg {
            height: 100%;
            width: 100%;
            color: #A4080E;
        }
    }

    &__wrapper {
        position: fixed;
        top: 0; left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 11;
        margin-left: 100vw;
        transition: .5s;
        display: flex;

        &.isOpen {
            margin-left: 0px;
        }
    }

    &__container {
        background-color: white;
        width: 100%;
        max-width: 400px;
        overflow: hidden;

        &--content {
            height: calc(100% - 60px);
            width: 100%;
            // background-color: rgba(128, 128, 128, 0.2);
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }

    &__language {
        border-top: 1px solid #F5F5F5;
        width: 100%;
        height: 60px;
        overflow: hidden;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        transition: .2s;
        
        &:hover {
            cursor: pointer;
            background-color: #A4080E05;
        }

        &--value {
            display: flex;
            align-items: center;

            & > img {
                height: 37px;
                width: 37px;
                object-fit: cover;
                border-radius: 50%;
            }

            & > div {
                margin-left: 10px;
            }
        }
    }

    &__close {
        width: 100%;
        height: 60px;
        display: flex;
        border-bottom: 1px solid #F5F5F5;
        align-items: center;
        justify-content: center;
        position: relative;

        & > img {
            height: 37px;
        }

        & > div {
            position: absolute;
            top: 0; right: 0;
            margin-right: 20px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &--btn {
            transition: .2s;

            &:hover {
                cursor: pointer;
                color: #A4080E;
            }
        }
    }

    &__product {

        &--item {
            padding: 15px 20px;
            border-bottom: 1px solid #F5F5F5;
            font-size: 14px;
            font-weight: 600;
            transition: .2s;

            &.is_actived {
                color: #A4080E;
            }

            &:hover {
                cursor: pointer;
                color: #A4080E;
            }
        }
    }
}