.acModal {

    &__info {

        & > div {
            margin-top: 10px;

            &:first-child{
                margin-top: 0px;
            }
        }
    }

    &__name {
        font-size: 20px;
        font-weight: 600;
        color: black;
    }

    &__price {

        &--title {
            color: rgba(128, 128, 128, 0.8);
        }

        &--value {
            font-weight: 600;

            &.VBC {
                color: #A4080E;
            }

            &.BNB {
                color: #f5c240;
            }

            & > img {
                height: 21px;
                width: 21px;
                transform: translateY(-1px);
            }
        }
    }

    &__subTitle {
        font-size: 13px;
        font-style: italic;
    }
}