.header {
    
    &__container {
        height: 80px;

        &--content {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

    &__leftCol {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        @media (max-width: 1199px) {
            justify-content: flex-start;
        }
    }

    &__logo {
        
        &:hover{
            cursor: pointer;
        }

        & > img {
            height: 37px;
        }
    }

    &__pagesMenu {
        display: flex;
        align-items: center;

        @media (max-width: 1199px) {
            margin-left: 25px;
        }

        &--item {
            display: flex;
            align-items: center;
            font-size: 14px;
            transition: .2s;
            margin-right: 25px;

            &.is_actived {
                color: #A4080E;

                .header__pagesMenu--itemIcon {
                    & > svg {

                        & > path {
                            fill: #A4080E;
                        }
                    }
                }
            }

            &:last-child {
                margin-right: 0px;
            }

            &:hover {
                cursor: pointer;
                color: #A4080E;

                .header__pagesMenu--itemIcon {
                    & > svg {

                        & > path {
                            fill: #A4080E;
                        }
                    }
                }
            }
        }

        &--itemIcon {

            & > svg {
            
                & > path {
                    fill: rgba(0, 0, 0, 0.7);
                    transition: .2s;
                }

                height: 21px;
                width: 21px;
            }
        }

        &--itemTitle {
            margin-left: 6px;

            @media (max-width: 1199px) {
                display: none;
                margin-left: 0px;
            }
        }
    }

    &__rightCol {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > div {
            margin-right: 25px;

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    &__language {

        &--content {
            transition: .2s;
            display: flex;
            align-items: center;

            &:hover{
                cursor: pointer;
                transform: translateY(-1px);
            }
        }

        &--flag {

            & > img {
                height: 37px;
                width: 37px;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        &--arrow {

            &.is_hide_border {
                color: white;
            }
        }
    }
    
    &__profile {

        &--avatar {
            height: 37px;
            width: 37px;
            border-radius: 50%;
            overflow: hidden;
            padding: 1px;
            background-color: white;
            transition: .2s;

            @media (max-width: 1199px) {
                height: 25px;
                width: 25px;
            }

            &:hover {
                cursor: pointer;
                transform: translateY(-1px);
            }

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }

    &__primaryBtn {
        height: 37px;
        background: linear-gradient(141deg, #B60B00 29.44%, #FF512B 89.87%);
        color: white;
        border-radius: 37px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        font-size: 12px;
        font-weight: 600;
        transition: .2s;
        width: max-content;

        @media (max-width: 1199px) {
            width: 100%;
            justify-content: center;
        }

        & > div {
            width: max-content;
        }

        &:hover {
            cursor: pointer;
            transform: translateY(-1px);
        }

        & > img {
            height: 25px;
            width: 25px;
            margin-right: 15px;
        }
    }

    &__noti {
        height: 21px;
        width: 21px;
        transition: .2s;

        &:hover {
            transform: translateY(-1px);
            cursor: pointer;
            color: #A4080E;
        }

        & > svg {
            width: 100%;
            height: 100%;
        }
    }
}   