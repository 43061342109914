.logoHeader {
    position: fixed;
    background-color: #F2F2F2;
    height: 110px;
    width: 100vw;
    max-width: 100vw;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1199px) {
        height: 80px;
    }
}