.deUser {
    min-height: calc(100vh - 110px);
    padding-bottom: 30px;

    @media (max-width: 1199px) {
        min-height: calc(100vh - 80px);
    }

    &__banner {
        height: 190px;
        border-radius: 5px 5px 0px 0px;
        overflow: hidden;

        & > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__info01 {
        padding: 0px 30px;
        display: flex;

        @media (max-width: 768px) {
            padding: 0px;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }

    &__avatar {
        min-width: 140px;
        height: 140px;
        width: 140px;
        background-color: white;
        margin-top: -40px;
        border-radius: 50%;
        overflow: hidden;
        padding: 1px;
        box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.5);

        & > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        & > div {
            height: 100%;
            width: 100%;
            border-radius: 50%;
        }
    }

    &__info02 {
        margin-left: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-left: 0px;
        }

        &--left {
            padding-top: 10px;
        }
        &--right {
            padding-top: 10px;
            display: flex;
        }
    }

    &__name {
        color: black;
        font-size: 21px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        font-weight: 600;

        @media (max-width: 768px) {
            display: flex;
            padding: 0px 10px;
            justify-content: center;
        }
    }

    &__info03 {
        padding: 0px 30px;
        width: 100%;

        @media (max-width: 768px) {
            padding: 0px;
            font-size: 13px;
        }

        &--content {
            background: #FFFFFF;
            box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.22);
            border-radius: 5px;
            width: 100%;
            height: 100%;
            font-size: 14px;
        }

        &--title {
            padding: 15px 15px 0px 15px;
            font-weight: 600;
            color: black;
        }

        &--value {
            padding: 15px;
            font-size: 13px;
        }

        &--modeTitle {
            display: flex;
        }

        &--modeTitleItem {
            padding: 10px;
            border: 1px solid rgba(164, 8, 13, 0.3);
            border-bottom-color: white;
            margin-left: 5px;
            transform: translateY(1px);
            border-radius: 4px 4px 0px 0px;

            &.is_actived {
                color: #A4080E;
                font-weight: 600;
            }

            &:first-child {
                margin-left: 0px;
            }
        }

        &--modeValue {
            background-color: white;
            border: 1px solid rgba(164, 8, 13, 0.3);
            padding: 10px;
            border-radius: 0px 0px 4px 4px;
        }
    }

    &__contactItem {
        display: flex;
        align-items: center;
        margin-top: 15px;

        &:first-child {
            margin-top: 0px;
        }

        &--icon {
            height: 20px;
            width: 20px;
            min-width: 20px;

            & > svg {
                height: 20px;
                width: 20px;
            }
        }

        &--value {
            width: 100%;
            margin-left: 15px;
            color: black;

            & > .title {
                color: rgba(128, 128, 128, .8);
            }
        }
    }

    &__des {
        width: 100%;
        max-height: 100px;
        overflow-x: hidden;
        overflow-y: scroll;

        &--item {
            margin-top: 10px;

            &:first-child {
                margin-top: 0px;
            }
        }
    }

    &__info04 {
        padding: 0px 30px;
        width: 100%;

        @media (max-width: 768px) {
            padding: 0px;
        }
    }

    &__mode {
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 2px solid #DEDEDE;

        & > div {
            margin-left: 30px;

            &:first-child {
                margin-left: 0px;
            }
        }

        &--item {
            font-weight: 600;
            color: #DEDEDE;
            padding: 5px 0px;
            border-bottom: 2px solid #A4080E00;
            transform: translateY(2px);
            transition: .2s;

            &:hover {
                cursor: pointer;
                color: #A4080E;
            }

            &.is_actived {
                color: #A4080E;
                border-bottom: 2px solid #A4080E;
            }
        }
    }

    &__addr {
        margin-top: 5px;
        display: flex;
        align-items: center;
        color: #737475;

        @media (max-width: 768px) {
            justify-content: center;
        }

        & > div {
            margin-right: 15px;

            &:last-child {
                margin-right: 0px;
            }
        }

        &--content {
            font-size: 14px;
            font-weight: 700;
        }

        &--icon {
            transition: .2s;

            &:hover {
                cursor: pointer;
                color: #A4080E;
            }

            & > svg {
                width: 17px;
                height: 17px;
            }
        }

        &--item {
            display: flex;
            justify-content: center;
            border-top: 1px solid #DEDEDE;
            padding: 7px 0px;
            background-color: white;
            transition: .2s;

            &:hover {
                cursor: pointer;
                background-color: #DEDEDE50;
            }

            &.is_actived {
                font-weight: bold;
            }

            &:last-child {
                border-bottom: 1px solid #DEDEDE;
            }
        }
    }

    &__itemsList {
        padding: 0px 30px;

        @media (max-width: 768px) {
            padding: 0px;
        }
    }

    &__follow {
        margin-top: 10px;
        display: flex;
        font-size: 14px;

        @media (max-width: 768px) {
            justify-content: center;
        }

        & > div {
            margin-left: 15px;

            &:first-child {
                margin-left: 0px;
            }
        }
    }

    &__feaBtn {
        background-color: white;
        height: 24px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 7px;
        transition: .4s;
        border: 1px solid #A4080E;
        color: #A4080E;
        border-radius: 3px;
        width: max-content;
        
        &.is_actived {
            background-color: #A4080E;
            color: white;
        }

        & > svg {
            height: 17px;
            width: 17px;
            margin-right: 5px;
        }

        &:hover {
            cursor: pointer;
            transform: translateY(-1px);
        }
    }
}