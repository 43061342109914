.feature-btn {
    box-shadow: 0 6px 11px rgba(57, 80, 203, 0.24) !important;
    color: white !important;
    border: none !important;
    background-color: #b60b00 !important;
    background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%) !important;
    border-radius: 5px;
    padding: 7px 17px;
    transition: .2s;
    font-weight: 600;
}

.feature-btn:hover{
    cursor: pointer;
    transform: translateY(-2px);
}

.normal-hover{
    transition: .2s;
}

.normal-hover:hover {
    box-shadow: 0 6px 11px rgba(57, 80, 203, 0.24) !important;
    cursor: pointer;
    transform: translateY(-2px);
}

.heavy-hover{
    transition: .2s;
}

.heavy-hover:hover {
    box-shadow: 0 6px 11px rgba(57, 80, 203, 0.24) !important;
    cursor: pointer;
    transform: translateY(-5px);
}

.feature-text{
    color: #eb4511;
}

.feature-like-share-item {
    /* color: white ;
    background-color: #b60b00 ; */

    background-color: rgba(255, 255, 255, 0);
    color: #b60b00 ;
    /* background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%); */
    transition: .2s;
    border-radius: 5px;
    border: 1px solid rgba(182, 12, 0, 0.7);
    padding: 5px;
    display: flex;
    align-items: flex-end;
    font-size: 15px;
}

.feature-like-share-item > svg {
    height: 25px;
    width: 25px;
}

.feature-like-share-item > div {
    color: rgba(255, 255, 255, 0.7);
}

.feature-like-share-item:hover {
    cursor: pointer;
    transform: translateY(-2px);
    background-color: #b60b00;
    background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%);

    svg {
        color: white;
    }
}

.countdown-stamp {
    color: black;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2px 4px;
    font-weight: bolder;
    border-radius: 5px;
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail-nft-countdown-stamp {
    color: white;
    background-image: linear-gradient(60deg, rgb(41, 50, 60) 0%, rgb(72, 85, 99) 100%);
    font-weight: bolder;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 29px;
    width: 29px;
}


.detail-like-share-item {
    color: #b60b00 ;
    background-color: white ;
    /* background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%); */
    transition: .2s;
    border-radius: 5px;
    border: 1px solid #b60b00;
    padding: 5px;
    display: flex;
    align-items: flex-end;
    font-size: 15px;
}

.detail-like-share-item > svg {
    height: 25px;
    width: 25px;
}

.detail-like-share-item > div {
    color: rgba(182, 11, 0, 0.7)
}

.detail-like-share-item:hover {
    cursor: pointer;
    /* transform: translateY(-2px); */
    border: 1px solid rgba(0, 0, 0, 0);
    background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%);
    background-color: #b60b00;
    color: white;
}

// .normal-btn{
//     border: none;
//     padding: 10px;
//     border-radius: 20px;
//     background-color: #b60b00;
//     background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%);
//     color: white;
//     display: flex;
//     align-items: center;
//     transition: .2s;
// }

// .normal-btn:hover{
//     transform: translateY(-2px);
//     box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3)
// }

.line-space {
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.normal-scrollbar {

    /* width */
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        cursor: pointer;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 1px rgba(128, 128, 128, 1); 
        border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3); 
        border-radius: 10px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.7); 
        cursor: pointer;
    }
}

.cursor-text{
    transition: .2s;
    &:hover {
        color: #A4080E;
        cursor: pointer;
    }
}

.list-all-wrapper {
    padding: 0px;

    @media (max-width: 767px) {
        padding: 0px 4px;
    }
}

.list-item-wrapper {
    padding-top: 25px;

    @media (max-width: 767px) {
        padding: 30px 4px 0px 4px !important;
    }
}

.list-all-products-container {
    min-height: calc(100vh - 110px);
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: 1199px) {
        min-height: calc(100vh - 80px);
    }
}