.footer {
    color: white;
    background-color: black;
    padding: 0px 0px 20px 0px;
    word-wrap: break-word;

    &__title {
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 30px;
        font-weight: 700;
    }

    &__line {
        margin: 20px 0px;
        height: 3px;
        width: 40%;
        border-radius: 3px;
        background-color: #A4080E;
    }

    &__linkVBC {
        color: white;

        &:hover{
            color: white;
        }
    }

    &__copyright {
        margin-top: 20px;
        padding: 20px 0px 0px 0px;
        border-top: 1px solid gray;
        text-align: center;
    }


    &__legal{
        &--item {
            display: flex;
            align-items: center;
            line-height: 14px;
            transition: .2s;

            & > svg {
                margin-right: 5px;
                height: 25px;
                width: 25px;
            }

            &:hover{
                color: #eb4511;
                cursor: pointer;
            }
        }
    }

    &__lang{
        &--item {
            display: flex;
            align-items: center;
            line-height: 14px;
            transition: .2s;

            & > img {
                height: 20px;
                width: 20px;
                object-fit: contain;
                margin-right: 15px;
            }

            &:hover{
                color: #eb4511;
                cursor: pointer;
            }
        }
    }
}