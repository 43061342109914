.dePro {

    &__like {
        display: flex;
        align-items: center;
        transition: .2s;

        &:hover {
            cursor: pointer;
            color: #A4080E;
        }

        & > svg {
            height: 23px;
            width: 23px;
        }

        & > div {
            margin-left: 5px;
        }
    }

    &__quantity {
        display: flex;
        align-items: center;
        transition: .2s;

        & > svg {
            height: 23px;
            width: 23px;
        }

        & > div {
            margin-left: 5px;
        }
    }

    &__countdown {
        display: flex;
        align-items: center;

        & > svg {
            height: 25px;
            width: 25px;
            color: #A4080E;
        }

        & > div {
            margin-left: 5px;
        }
    }

    &__renderPrice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        &:first-child{
            margin-top: 0px;
        }

        &--title{
            color: #A8A6A6;

           & > span {
                & > strong {
                    color: black;
                }
           }
        }

        &--value {
            font-weight: 600;

            &.VBC {
                color: #A4080E;
            }

            &.BNB {
                color: #f5c240;
            }

            & > img {
                height: 23px;
                width: 23px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 4px;
            }
        }
    }

    &__name {
        flex-grow: 1;

        &--value {
            width: fit-content;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            color: black;
            font-size: 24px;
            font-weight: 600;
            transition: .2s;

            &:hover {
                color: #A4080E;
                cursor: pointer;
            }
        }
    }

    &__moreOptions {
        min-width: 23px;
        height: 23px;
        width: 23px;
        margin-left: 10px;
        transition: .2s;

        &:hover {
            cursor: pointer;
            color: #A4080E;
        }
    }

    &__validateInfo {

        &--title {
            color: #BDBDBD;
            font-size: 13px;
            margin-top: 5px;
        }

        &--content {
            margin-top: 5px;
            display: flex;
            align-items: center;
        }

        &--avatar {
            height: 37px;
            width: 37px;
            min-width: 37px;
            background-color: white;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0px 0px 1px rgba(128, 128, 128, 0.1);
            margin-right: 10px;

            & > img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }

            & > div {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        &--value {
            width: calc(100% - 47px);
        }

        &--mainValue {
            display: flex;
            overflow: hidden;
            width: 100%;
            display: flex;
            align-items: center;
            font-weight: 600;
        }

        &--subValue {
            display: flex;
            overflow: hidden;
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #BDBDBD;
        }

        &--arrow {
            display: flex;

            & > div {
                margin-left: 10px;
                transition: .2s;

                &:hover{
                    cursor: pointer;
                    color: #A4080E;
                }

                & > svg {
                    height: 15px;
                    width: 15px;
                }
            }
        }

        &--subArrow {
            display: flex;

            & > div {
                margin-left: 10px;
                transition: .2s;

                &:hover{
                    cursor: pointer;
                    color: #A4080E;
                }

                & > svg {
                    height: 14px;
                    width: 14px;
                }
            }
        }
    }

    &__timeLine {
        position: relative;

        &--line {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-left: 1px dashed black;
            margin-top: 10px;

            & > div {
                position: relative;
            }
        }

        &--dots {
            position: absolute;
            top: 0; left: 0;
            height: 10px;
            width: 10px;
            background-color: #A4080E;
            transform: translateY(-4px) translateX(-5px);
            border-radius: 50%;
        }

        &--container {
            padding-bottom: 25px;
            padding-left: 20px;
            display: flex;
        }

        &--content {
            flex-grow: 1;
        }

        &--address {
            font-weight: 600;
            color: black;
        }

        &--price {
            font-weight: 600;

            &.VBC {
                color: #A4080E;
            }

            &.BNB {
                color: #f5c240;
            }
        }

        &--priceIcon {
            height: 23px;
            width: 23px;
            border-radius: 50%;
            object-fit: cover;
        }

        &--time {
            margin-top: 5px;
            font-size: 13px;
            font-weight: 500;
            color: #BDBDBD;
        }

        &--txHash {
            color: #A8A6A6;
            height: 23px;
            width: 23px;
            min-width: 23px;
            margin-left: 10px;
            transition: .2s;

            &:hover {
                color: #A4080E;
                cursor: pointer;
            }

            & > svg {
                height: 23px;
                width: 23px;
            }
        }
    }
}