.deBunVau {
    min-height: calc(100vh - 110px);
    padding-bottom: 30px;

    @media (max-width: 1199px) {
        min-height: calc(100vh - 80px);
    }

    & > div {
        margin-top: 20px;
    }

    &__validate {
        display: flex;
        justify-content: center;

        & > div {
            margin-left: 30px;

            &:first-child {
                margin-left: 0px;
            }
        }
    }

    &__comp1 {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.13);
        border-radius: 3px;

        & > div {
            margin-top: 10px;

            &:first-child {
                margin-top: 0px;
            }
        }

        &--card {
            display: flex;
            justify-content: center;

            & > div {
                margin-right: 10px;
                font-size: 12px;
                padding: 2px 5px;
                border-radius: 2px;
                margin-right: 10px;
            
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }

    &__comp2 {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.13);
        border-radius: 3px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__totalPrice {

        &--value {
            display: flex;
            align-items: center;

            & > div {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }

        &--buyPrice {
            color: #B8B8B8;
            text-decoration: line-through;
        }

        &--icon {

            & > img {
                height: 25px;
                width: 25px;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        &--disPrice {
            font-weight: 700;
        }

        &--discount {
            height: 25px;
            border-radius: 25px;
            background-color: #A4080E;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            color: white;
            font-size: 13px;
        }
    }

    &__comp3 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--title {
            font-weight: 700;
            font-size: 20px;
        }

        &--leastToDis {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-style: italic;
            color: #A4080E;

            & > svg {
                margin-right: 5px;
            }
        }

        &--btns {
            display: flex;
            align-items: center;
        }

        &--selectAll {
            margin-right: 20px;
            display: flex;
            align-items: center;
            transition: .2s;
            
            &:hover{
                color: #A4080E;
                cursor: pointer;
            }

            & > svg {
                margin-right: 3px;
            }
        }

        &--selected {
            font-size: 14px;
            font-weight: 600;

            & > .price {
                color: #A4080E;

                &.discount {
                    color: #B8B8B8;
                    text-decoration: line-through;
                }
            }
        }
    }
}