.waSi {

    &__vbcWaItem {
        margin-top: 15px;
        padding: 10px;
        border-radius: 3px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.13);
        transition: .2s;

        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.33);
        }
    }
}