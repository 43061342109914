.waSi {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 20;
    display: flex;
    margin-left: 100vw;
    transition: .5s;

    &.open {
        margin-left: 0px;
    }

    &__fade {
        height: 100%;
        flex-grow: 1;
    }

    &__container {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 25%;
        background-color: white;
        min-width: 320px;
        padding: 20px 5px;
    }

    &__comp01 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__close {
        transition: .2s;

        &:hover {
            cursor: pointer;
            transform: translateY(-1px);
        }
    }

    &__network {
        height: 37px;
        border-radius: 37px;
        padding: 0px 15px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.13);
        margin-right: 20px;
        transition: .2s;
        display: flex;
        align-items: center;
        font-weight: 600;

        &--name {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        &--caret {
            margin-left: 5px;
        }

        & > img {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.23);
        }
    }

    &__connectTitle {
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
    }
    
    &__selectWallet {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.13);
        margin-top: 20px;
        transition: .2s;

        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.33);
        }

        & > img {
            height: 40px;
            width: 40px;
            margin-right: 20px;
        }

        &--createWallet {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            & > .create {
                color: #dc3545;
                text-decoration: underline #dc354500;
                transition: .2s;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline #dc3545;
                }
            }

            & > .icon {
                & > svg {
                    height: 50px;
                    width: 50px;
                    color: rgba(220, 53, 69, 0.9);
                }
            }

            & > .title {
                font-weight: 500;
                color: black;
            }

            & > div {
                text-align: center;
                margin-top: 10px;

                &:first-child {
                    margin-top: 0px;
                }
            }
        }
    }

    &__disconnect {

        &--content {
            width: fit-content;
            color: #dc3545;
            text-decoration: underline #dc354500;
            transition: .2s;

            &:hover {
                cursor: pointer;
                text-decoration: underline #dc3545;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;

        &--name {
            font-size: 20px;
        }

        &--img {
            height: 70px;
            width: 70px;
            border-radius: 50%;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
            padding: 10px;

            & > img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}