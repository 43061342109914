.header {
    position: fixed;
    background-color: rgba(128, 128, 128, 0);
    height: 110px;
    width: 100vw;
    max-width: 100vw;
    z-index: 10;

    @media (max-width: 1199px) {
        height: 80px;
        box-shadow: 0px 0px 3px rgba(128, 128, 128, 0.3);
    }
}