.deNftSet {
    height: calc(100vh - 110px);
    overflow: hidden;

    @media (max-width: 1199px) {
        height: calc(100vh - 80px);
    }

    @media (max-width: 1099px) {
        overflow: auto;
    }

    &__container {
        height: calc(100vh - 110px);
        overflow: hidden;

        @media (max-width: 1199px) {
            height: calc(100vh - 80px);
        }

        @media (max-width: 1099px) {
            height: max-content;
            overflow: auto;
        }
    }

    &__col {
        height: calc(100vh - 110px);
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0px 10px 20px 5px;

        &.right {
            position: relative;
            padding-bottom: 170px;
        }

        &.left {
            padding-right: 50px;
        }

        @media (max-width: 1199px) {
            height: calc(100vh - 80px);
        }

        @media (max-width: 1099px) {
            height: max-content;
            overflow: auto;

            &.left {
                padding-bottom: 5px;
                padding-right: 10px;
            }
        }

        & > div {
            margin-top: 20px;

            &.fake_comp {
                position: relative;

                & > div {
                    margin-top: 20px;

                    &:first-child {
                        margin-top: 0px;
                    }
                }
            }

            &.boxSha {
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.13);
                border-radius: 3px;
            }

            &.shortMar {
                margin-top: 10px;
            }
        }
    }

    &__comp1 {
        width: 100%;
        padding: 20px;
    }

    &__product {
        width: 100%;
        padding-top: 75%;
        position: relative;

        &--container {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            border-radius: 3px;
            overflow: hidden;
        }
    }

    &__comp2 {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    &__comp3 {
        width: 100%;
        padding: 20px;
    }

    &__comp4 {
        display: flex;
    }

    &__tokenId {
        color: #BDBDBD;
    }

    &__comp5 {
        display: flex;

        & > div {
            margin-right: 10px;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 2px;
            margin-right: 10px;
        
            &:last-child {
                margin-right: 0px;
            }
        }
    }

    &__mode {
        display: flex;
        border-bottom: 2px solid #DEDEDE;

        &--item {
            margin-left: 40px;
            color: #DEDEDE;
            font-weight: 600;
            padding: 5px 0px;
            border-bottom: 2px solid #A4080E00;
            transform: translateY(2px);
            transition: .2s;

            &:hover {
                cursor: pointer;
                color: #A4080E;
                border-bottom: 2px solid #A4080E;
            }

            &.is_active {
                color: #A4080E;
                border-bottom: 2px solid #A4080E;
            }

            &:first-child {
                margin-left: 0px;
            }
        }
    } 

    &__properties {

        &--title {
            font-size: 13px;
            font-weight: 600;
            color: black;
        }

        &--item {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
        }

        &--name {
            text-transform: capitalize;
        }

        &--value {
            font-weight: 600;
        }
    }

    &__btnsComp {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: white;
        width: 100%;
        height: 150px;
        z-index: 5;

        @media (max-width: 1099px) {
            position: fixed;
        }
        
        &--container {
            border-top: 1px solid #A4080E;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        &--content {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &--btn {
            margin-left: 20px;
            width: 40%;
            display: flex;
            justify-content: center;
            padding: 7px 0px;
            border-radius: 5px;
            font-weight: 600;
            transition: .2s;

            &:hover{
                cursor: pointer;
                transform: translateY(-2px);
            }

            &:first-child {
                margin-left: 0px;
            }

            &.buyNow {
                color: white;
                background: linear-gradient(141deg, #B60B00 29.44%, #FF512B 89.87%);
            }

            &.placeABid {
                color: white;
                background: linear-gradient(88.87deg, #000000 -15.36%, #A29F9F 161.26%);
            }
        }
    }

    &__btnStatus {
        display: flex;
        align-items: center;

        &--icon {
            width: 35px;
            margin-right: 10px;
        }

        &--title {
            font-weight: 700;
            font-size: 17px;
            color: #BDBDBD;
        }

        &--subTitle {
            color: #007bff;
            text-decoration: underline #007bff00;
            transition: .2s;

            &:hover {
                text-decoration: underline #007bff;
                cursor: pointer;
            }
        }
    }

    &__nftsList {
        padding: 20px 0px;

        &--title {
            display: flex;
            align-items: center;
            padding: 0px 20px;
            color: #A4080E;

            & > svg {
                height: 23px;
                width: 23px;
                margin-right: 5px;
            }

            & > div {
                font-weight: 600;
            }
        }

        &--line {
            margin: 0px 20px;
            height: 1px;
            margin-top: 10px;
            background-color: #A4080E;
        }

        &--row {
            margin-top: 10px;
        }

        &--col {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;

            &.sel {
                justify-content: flex-start;
            }

            &.sta {
                justify-content: flex-end;
            }

            &.hea {
                color: #A4080E;
            }
        }

        &--tokenId {
            font-weight: 600;
            color: #767676;
            transition: .2s;

            &:hover {
                cursor: pointer;
                color: #A4080E;
            }
        }

        &--status {
            background-color: #DEDEDE;
            color: white;
            border-radius: 3px;
            padding: 3px 10px;
            font-size: 13px;

            &.ONSALE {
                background-color: #A4080E;
            }
        }

        &--checkbox {
            color: #DEDEDE;

            &.is_can_select, &.select_all {
                color: #767676;
                transition: .2s;

                &:hover {
                    cursor: pointer;
                    color: #A4080E;
                }
            }

            &.is_selected {
                color: #A4080E;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.description-group {
    max-width: 100%;
    overflow-x: hidden;

    .content {
        max-height: 150px;
        overflow-y: hidden;
        position: relative;
        transition: 0.2s;
        max-width: 100%;

        &:before {
            z-index: 0;
            content: '';
            height: 100px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(transparent, rgb(255, 255, 255));
        }

        &.show {
            max-height: 100%;
    
            &:before {
                height: 0;
            }
        }
    }

    .btn-wrapper {
        display: none;

        &.show {
            display: block;

            & > button {
                border: none;
                background-color: white;
                transition: .2s;
    
                &:hover{
                    transform: translateY(-2px);
                }
            }
        }
    }
}