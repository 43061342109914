//  Core

.avatar-icon-wrapper {
  display: inline-block;
  margin-right: ($spacer * 0.25);
  position: relative;
  font-size: 1.2rem;
  transition: $transition-base;

  &:last-child {
    margin-right: 0;
  }

  .badge {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.avatar-icon {
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  transition: $transition-base;
  opacity: 1;
  @include border-radius(50px);

  &.rounded {
    @include border-radius(($border-radius) !important);
  }
}

.avatar-icon-xl {
  font-size: 1.4rem;

  .avatar-icon {
    width: 64px;
    height: 64px;
    line-height: 64px;
  }
}

.avatar-icon-lg {
  font-size: 1.2rem;

  .avatar-icon {
    width: 54px;
    height: 54px;
    line-height: 54px;
  }
}

.avatar-icon-sm {
  font-size: 0.8rem;
  font-weight: bold;

  .avatar-icon {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
}

.avatar-icon-xs {
  font-size: .7rem;
  font-weight: bold;

  .avatar-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    transition: transform .2s;
  }
}

.avatar-icon {
  box-shadow: 0 0 0 3px $white;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.avatar-wrapper-overlap {
  display: flex;

  .avatar-icon-wrapper {
    z-index: 5;
    margin-left: -18px;

    &:hover {
      z-index: 7;
    }

    &.avatar-icon-xl {
      margin-left: -30px;
    }

    &.avatar-icon-lg {
      margin-left: -24px;
    }

    &.avatar-icon-sm {
      margin-left: -14px;
    }

    &.avatar-icon-xs {
      margin-left: -10px;
    }

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.avatar-wrapper-overlap-hover {

  .avatar-icon-wrapper {

    &:hover {
      z-index: 5;
    }
  }

  &:hover {
    .avatar-icon-wrapper {
      margin-left: -18px;

      &:first-child {
        transform: translateX(-22px);
      }

      &:last-child {
        transform: translateX(22px);
      }
    }

    .avatar-icon-xl {
      margin-left: -30px;

      &:first-child {
        transform: translateX(-34px);
      }

      &:last-child {
        transform: translateX(34px);
      }
    }

    .avatar-icon-lg {
      margin-left: -24px;

      &:first-child {
        transform: translateX(-28px);
      }

      &:last-child {
        transform: translateX(28px);
      }
    }

    .avatar-icon-sm {
      margin-left: -14px;

      &:first-child {
        transform: translateX(-18px);
      }

      &:last-child {
        transform: translateX(18px);
      }
    }

    .avatar-icon-xs {
      margin-left: -10px;

      &:first-child {
        transform: translateX(-14px);
      }

      &:last-child {
        transform: translateX(14px);
      }
    }
  }
}

// Initials avatars

.avatar-initials {
  text-align: center;
  text-transform: uppercase;
}

