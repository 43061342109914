.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotElastic 1s infinite linear;
    margin-left: 15px;

    &.wrapperBalance, &.wrapperBalance::before, &.wrapperBalance::after {
        background-color: #da3b7a;
        color: #da3b7a;
    }

    &.bnbBlance, &.bnbBlance::before, &.bnbBlance::after {
        background-color: #f5c240;
        color: #f5c240;
    }

    &.vbcBalance, &.vbcBalance::before, &.vbcBalance::after {
        background-color: #b60b00;
        color: #b60b00;
    }
  }
  
  .dot-elastic::before, .dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotElasticBefore 1s infinite linear;
  }
  
  .dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotElasticAfter 1s infinite linear;
  }
  
  @keyframes dotElasticBefore {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dotElastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dotElasticAfter {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }