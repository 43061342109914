
/* @import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter'); */
@import url('https://rsms.me/inter/inter.css');

body{
  padding: 0px !important;
  margin: 0px !important;
  background-color: white !important;
  font-size: 15px !important;
  line-height: normal !important;
  font-family: 'Inter', sans-serif !important;
  overflow-x: hidden;
  color: rgba(0, 0, 0, 0.7) !important;

  @media (max-width: 1024px) {
    font-size: 13px !important;
  }
}

.btn-gradient-info {
  box-shadow: 0 6px 11px rgba(57, 80, 203, 0.24) !important;
  color: white !important;
  border: none !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  background-color: #b60b00 !important;
  background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%) !important;
}

.btn-gradient-info.right{
  margin-right: -20px;
}

.btn-gradient-info.left{
  margin-left: -20px;
}

.bg-gradient-info{
  background-color: #b60b00 !important;
  background-image: linear-gradient(315deg, #eb4511 0%, #b60b00 74%) !important;
  box-shadow: 0 6px 11px rgba(57, 80, 203, 0.24);
}

.shadow-card-item{
  box-shadow: 0 6px 15px rgb(36 37 38 / 8%);
}

.text-info-new{
  color: #eb4511;
}

.cursor-pointer{
  cursor: pointer;
}

.input-number-hide-arrow {
  &[type=number]{
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
  }
}