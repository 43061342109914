.header__fade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: -1;

    @media (max-width: 1199px) {
        background-color: rgba(255, 255, 255, 0.9);
    }

    &.is_scroll {
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(20px);
        box-shadow: 0px 0px 3px rgba(128, 128, 128, 0.3);
    }
}