


.normal-input{
    background-color: none;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px 10px;

    &:focus{
        outline: 1px solid $first;
        border: none;
    }
}