.waSi {

    &__bala {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.23);
        border-radius: 5px;
        overflow: hidden;

        &--opt {
            transition: .2s;

            &:hover {
                transform: translateY(-1px);
                cursor: pointer;
            }
        }

        &--title {
            display: flex;
            justify-content: space-between;
            color: white;
            align-items: center;
            padding: 10px;
            text-transform: capitalize;

            &.vbcAcc {
                background-color: #b60b00;
            }

            &.bnbAcc {
                background: #f5c240;
            }

            &.wrapper {
                background-color: #da3b7a;
            }

            &.total {
                background-color: #4fb779;
                display: flex;
                justify-content: center;
            }
        }

        &--value {
            padding: 10px;

            &.total {
                display: flex;
                justify-content: center;
            }
        }
    }

    &__renderToken {
        display: flex;
        align-items: center;

        & > img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            object-fit: cover;
            border-radius: 50%;

            @media (max-width: 1024px) {
                width: 20px;
                height: 20px;
            }
        }

        & > div {
            font-weight: bold;

            &.VBC {
                color: #b60b00;
            }

            &.BNB {
                color: #f5c240;
            }

            &.WBNB , &.WVBC {
                color: #da3b7a;
            }
        }
    }

    &__maxBtn {
        margin-left: 10px;
        font-size: 13px;
        font-weight: bold;
        padding: 5px 10px;
        border: 1px solid rgba(128, 128, 128, 0.3);
        border-radius: 5px;
        transition: .2s;

        &:hover {
            cursor: pointer;
            border: 1px solid #b60b00;
            color: #b60b00;
        }
    }
}