.header__smallInfo {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom:  1px solid #F5F5F5;

    &.is_hide_border {
        border-bottom:  1px solid #F5F5F500;
    }

    & > div {
        font-size: 13px;
        display: flex;
        align-items: center;
    }

    &--item {
        display: flex;
        align-items: center;
        margin-right: 25px;
        color: #B8B8B8;
        transition: .2s;

        &:hover{
            cursor: pointer;
            color: rgba(0, 0, 0, 0.7);
        }

        &:last-child {
            margin-right: 0px;
        }

        & > .icon {
            margin-right: 8px;

            & > svg {
                height: 17px;
                width: 17px;
            }
        }
    }
}