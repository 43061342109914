

.normal-pill-btn{
    background-color: $primary-red-bg-color;
    background-image: $primary-red-bg-image;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;

    &:hover{
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }
}

.disable-pill-btn{
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
    color: rgba(128, 128, 128, 0.3);
    border-radius: 20px;
    border: none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}

.small-pill-btn{
    background-color: $primary-red-bg-color;
    background-image: $primary-red-bg-image;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 20px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    font-size: 13px;

    &:hover{
        cursor: pointer;
        transform: translateY(-1px);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }
}

.small-outline-btn{
    background-color: white;
    background-image: linear-gradient(315deg, rgba(234, 67, 16, .05) 0%, rgba(234, 67, 16, .05) 74%);
    color: rgba(234, 67, 16, .7);
    border: 1px solid rgba(234, 67, 16, .7);
    border-radius: 20px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    font-size: 13px;

    &:hover{
        cursor: pointer;
        transform: translateY(-1px);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }
}

.small-disable-pill-btn{
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
    color: rgba(128, 128, 128, 0.3);
    border-radius: 20px;
    border: none;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    font-size: 13px;
}

.normal-btn{
    height: 35px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    background-color: $primary-red-bg-color;
    background-image: $primary-red-bg-image;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: .2s;

    & > svg {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }

    &:hover{
        transform: translateY(-2px);
        cursor: pointer;
    }
}

.normal-outline-btn{
    height: 35px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    color: rgba(182, 12, 0, 0.8);
    font-weight: bold;
    transition: .2s;
    border: 1px solid rgba(182, 12, 0, 0.5);
    
    & > svg {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }

    &:hover{
        transform: translateY(-2px);
        cursor: pointer;
        color: rgba(182, 12, 0, 1);
        border: 1px solid rgba(182, 12, 0, 0.7);
    }
}

.normal-dark-btn{
    height: 35px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(60deg,#29323c,#485563)!important;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: .2s;

    &:hover{
        transform: translateY(-2px);
        cursor: pointer;
    }
}

.disabled-normal-btn{
    height: 35px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    background: #DEDEDE;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    
    & > svg {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }
}

.card-btn {
    height: 26px;
    padding: 0px 10px 0px 10px;
    display: flex;
    align-items: center;
    background-color: $primary-red-bg-color;
    background-image: $primary-red-bg-image;
    border-radius: 3px;
    color: white;
    transition: .2s;
    font-size: 12px;

    &:hover{
        transform: translateY(-2px);
        cursor: pointer;
    }

    @media (max-width: 767px) {
        font-size: 10px;
        height: 22px;
    }
}

.disabled-card-btn {
    height: 26px;
    padding: 0px 10px 0px 10px;
    display: flex;
    align-items: center;
    // background-color: #ffffff;
    background: #DEDEDE;
    // background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
    // color: rgba(128, 128, 128, 0.3);
    color: white;
    border-radius: 5px;
    transition: .2s;
    font-size: 12px;

    @media (max-width: 767px) {
        font-size: 10px;
        height: 22px;
    }
}