.cusAlert {

    &__mainTitle {
        font-size: 26px;
        font-weight: 600;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 22px;
        }
    }

    &__subTitle {
        font-size: 14px;
        margin-top: 20px;
        font-weight: 400;

        @media (max-width: 768px) {
            font-size: 13px;
        }
    }

    &__btns {
        margin-top: 20px;

        & > button {
            margin-left: 30px;

            &:first-child {
                margin-left: 0px;
            }
        }
    }
}