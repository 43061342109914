

.modalFull {
    margin: 0px !important;
    max-width: none !important;

    & > .modal-content {
        background-color: rgba(0, 0, 0, 0);
    }

    &__body {
        background-color: rgba(0, 0, 0, 0);
        padding: 0px !important;

        &--content {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.modal {

    &-content {
        box-shadow: none;
    }

    &-backdrop {
        background-color: rgba(0, 0, 0, 0.7);
    }

    &-title {
        width: 100%;
        height: 100%;
    }

    &-header{
        background-color: #A4080E;
        color: white;
        border-radius: 0.6rem 0.6rem 0px 0px;
        padding: 10px 1rem;
        font-weight: 18px !important ;
        line-height: 20px !important;

        &-container{
            width: 100%;
            height: 100%;
            position: relative;
            height: 30px;

            & > .title {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;

                & > div {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    font-weight: 700;
                    padding-right: 20px;
                }
            }

            & > .close-icon {
                background-color: none;
                position: absolute;
                right: 0;
                top: 0;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                transition: .2s;
                border-radius: 50%;

                &:hover{
                    cursor: pointer;
                    transform: translateY(-1px);
                }

                & > svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}

.dot-overtaking {
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: transparent;
    color: black;
    margin: -1px 0;
    box-shadow: 0 -20px 0 0;
    animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);

    &.primary{
        color: #A4080E;

        &::before,  &::after{
            color: #A4080E;
        }
    }

    &.white {
        color: white;

        &::before,  &::after{
            color: white;
        }
    }

    &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: transparent;
        color: black;
        box-shadow: 0 -20px 0 0;
    }

    &::before{
        animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
        animation-delay: .3s;
    }

    &::after{
        animation: dotOvertaking 1.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
        animation-delay: .6s;
    }

    @keyframes dotOvertaking {
        0% {
            transform: rotateZ(0deg);
        }
        100% {
            transform: rotateZ(360deg);
        }
    }
}

.dropdown {

    &__item {
        display: flex;
        font-size: 14px;
        align-items: center;

        & > svg {
            height: 18px;
            width: 18px;
            margin-right: 7px;
        }

        & > img {
            height: 18px;
            width: 18px;
            margin-right: 7px;
            object-fit: cover;
            border-radius: 50%;
            transform: translateY(1px);
        }
    }

    &-menu {
        box-shadow: 0px 0px 2px rgba(128, 128, 128, 0.7);
        margin: 0px;
    }
}